<template>
  <div class="club">
    <!-- HERO -->
    <div class="club-hero">
      <div class="container">
        <div class="club-hero__descr">
          <img
            src="@/assets/img/magnum-club/magnum-club__red.svg"
            class="club-hero__logo"
            alt="logo"
            width="154"
            height="71"
            data-aos-duration="800"
            data-aos="fade-down"
          />
          <div
            class="club-hero__slogan"
            data-aos-duration="1000"
            data-aos="fade-right"
          >
            {{ $t("loyaltyCard") }}
          </div>
          <a
            class="club-hero__btn"
            data-aos-duration="1000"
            data-aos="fade-right"
            href="https://t.me/magnumuz_bot"
            target="_blank"
          >
            {{ $t("activateCard") }}
          </a>
        </div>
      </div>
      <img
        src="@/assets/img/club-page/main.png"
        alt="image"
        class="club-hero__img"
        data-aos="zoom-in"
        data-aos-duration="1000"
      />
    </div>

    <div class="container">
      <!-- possibility -->

      <div class="club-possibility">
        <img
          class="club-possibility__img"
          src="@/assets/img/about/about__loyalty-img.jpg"
          alt=""
        />
        <div class="club-possibility__content">
          <h3 class="club-possibility__title">
            {{ $t("moreOps") }}
          </h3>
          <p class="club-possibility__text">
            {{ $t("clubDescr") }}
          </p>
          <p class="club-possibility__text">
            {{ $t("fullFam") }}
          </p>
        </div>
      </div>

      <div class="club-loyalty">
        <h3 class="club-loyalty__title club__title">
          {{ $t("activateLoyalty") }}
        </h3>
        <div class="club-loyalty__inner">
          <div class="club-loyalty__item">
            <img
              class="club-loyalty__icon"
              src="@/assets/img/club-page/club-loyalty-1.svg"
            />
            <div class="club-loyalty__content">
              <p class="club-loyalty__text">
                {{ $t("insideShop") }}
                <a class="club-loyalty__link" @click="showModal">
                  {{ $t("fillForm") }}
                </a>
              </p>
            </div>
          </div>
          <div class="club-loyalty__item">
            <img
              class="club-loyalty__icon"
              src="@/assets/img/club-page/club-loyalty-2.svg"
            />
            <div class="club-loyalty__content">
              <p class="club-loyalty__text">
                {{ $t("fillCorrectly") }}
              </p>
            </div>
          </div>
          <div class="club-loyalty__item">
            <img
              class="club-loyalty__icon"
              src="@/assets/img/club-page/club-loyalty-3.svg"
            />
            <div class="club-loyalty__content">
              <p class="club-loyalty__text">
                {{ $t("takePlessure") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="club-terms">
        <h3 class="club-terms__title club__title">
          {{ $t("mc_card_condition") }}
        </h3>
        <div class="club-terms__inner">
          <div class="club-terms__item">
            <div class="club-terms__number">1.</div>
            <div class="club-terms__content">
              <p class="club-terms__text">{{ $t("mc_card_condition_1") }}</p>
            </div>
          </div>
          <div class="club-terms__item">
            <div class="club-terms__number">2.</div>
            <div class="club-terms__content">
              <p class="club-terms__text">
                {{ $t("mc_card_condition_2") }}
              </p>
            </div>
          </div>
          <div class="club-terms__item">
            <div class="club-terms__number">3.</div>
            <div class="club-terms__content">
              <p class="club-terms__text">
                {{ $t("mc_card_condition_3") }}
              </p>
            </div>
          </div>
          <div class="club-terms__item">
            <div class="club-terms__number">4.</div>
            <div class="club-terms__content">
              <p class="club-terms__text">
                {{ $t("mc_card_condition_4") }}
              </p>
            </div>
          </div>
        </div>
        <a class="club-terms__link" :href="magnumRules">
          {{ $t("programRules") }}
        </a>
      </div>

      <!-- REGISTRATION -->
      <div class="club-registration">
        <div class="club-registration__content">
          <div class="club-registration__info">
            <h2
              class="club-registration__title club__title"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              {{ $t("registerLoyalty") }}
              <a
                class="color-pink"
                href="https://t.me/magnumuz_bot"
                target="_blank"
              >
                @mgmuz_bot
              </a>
            </h2>
            <div
              class="club-registration__descr"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {{ $t("personalDiscount") }}
            </div>
            <img
              class="club-registration__img"
              src="@/assets/img/club-page/cart.png"
              alt=""
            />
          </div>
          <a
            href="https://t.me/magnumuz_bot"
            target="_blank"
            class="club-registration__button"
          >
            {{ $t("activate") }}
          </a>
        </div>
        <div class="club-registration__qr">
          <!-- <img src="@/assets/img/club-page/club-qr.svg" alt="" /> -->
          <!-- <img src="@/assets/img/club-page/qr-code.png" alt="" /> -->
          <img src="@/assets/img/club-page/new-qr-code.png" alt="" />
        </div>
      </div>

      <app-modal-registration
        v-if="isVisibleRegistrationModal"
        @close="isVisibleRegistrationModal = false"
      />
      <app-modal-check
        v-if="isVisibleCheckModal"
        @close="isVisibleCheckModal = false"
      />
    </div>
  </div>
</template>

<script>
import {
  getGooglePlay,
  getAppStore,
  getAppGallery,
  getMagnumClubLink,
} from "@/api/links";
export default {
  name: "MagnumClub",
  components: {
    AppModalRegistration: () =>
      import("@/components/modal/AppModalRegistration.vue"),
    AppModalCheck: () => import("@/components/modal/AppModalRegistration.vue"),
    // IconServices: () => import("@/components/icons/IconServices.vue"),
  },
  data() {
    return {
      isVisibleRegistrationModal: false,
      isVisibleCheckModal: false,
      googlePlayLink: null,
      appStoreLink: null,
      appGalleryLink: null,
      magnumClubLink: null,
      magnumRules: null,
      magnumQuestions: null,
      magnumPolicy: null,
      magnumOffer: null,
    };
  },
  created() {
    getGooglePlay().then((googlePlay) => {
      this.googlePlayLink = googlePlay.data.attributes.link;
    });
    getAppStore().then((appStore) => {
      this.appStoreLink = appStore.data.attributes.link;
    });
    getAppGallery().then((appGallery) => {
      this.appGalleryLink = appGallery.data.attributes.link;
    });
    getMagnumClubLink().then((magnumClubLink) => {
      this.magnumClubLink = magnumClubLink.attributes.link_to_rules;
      this.magnumRules = magnumClubLink.attributes.link_to_rules;
      this.magnumPolicy = magnumClubLink.attributes.confidencial_policy;
      this.magnumOffer = magnumClubLink.attributes.public_offer;
      console.log("magnumClubLink", magnumClubLink);
    });
  },
  methods: {
    showModal() {
      this.isVisibleRegistrationModal = true;
      document.querySelector("body").classList.add("no-scroll");
    },
    link(link) {
      return this.$helpers.getAbsolutePath(link);
    },
  },
};
</script>

<style lang="sass">
.magnum-club__btn + .magnum-club__btn
  margin-top: 0

@media (max-width: 575px)
  .magnum-club__btn + .magnum-club__btn
    margin-left: 0
</style>
